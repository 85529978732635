@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  padding: 5px;

  @include md-min {
    gap: 9px;
    flex-flow: row nowrap;
    padding: 40px 0;
  }

  @include lg-min {
    gap: 20px;
  }
}

.loginPage {
  padding: 6px;
}

.container {
  max-width: $content-wrapper-medium-width;
  margin: 0 auto;
}

.breadCrumbs {
  letter-spacing: 0.5px;
  font-size: 14px;
  max-width: $content-wrapper-max-width;
  margin: 0 auto;
  border-bottom: 1px solid $color-border;
  padding: 7px 0;
  margin-top: 15px;
}
